import './polyfills/scrollingElement' // fix for scrolling
import './polyfills/forEach' // IE fix forEach-function
import vh_fix from './polyfills/vh_fix' // 100vh fix for smartphones
import './polyfills/Promise'
import './polyfills/details-element-polyfill'

import { gsap } from "gsap"
import { ScrollTrigger } from 'gsap/ScrollTrigger.js'
gsap.registerPlugin(ScrollTrigger)

import scrolling from './components/scrolling' // scrolling
import navigation_mobile from './components/mobile_nav'
import scrolllinks from './components/scrolllinks' // scrolllinks
import slideshows from './components/slideshows' // swiper
import form_handling from './components/forms' // forms
import accordions from './components/accordions' // accordions

let mq900_max = window.matchMedia('screen and (max-width: 900px)')

document.addEventListener('DOMContentLoaded',() =>{

  new vh_fix()
  if(!document.querySelector("body").classList.contains('legal')){
    new scrolling( gsap, ScrollTrigger )
  }

  if(mq900_max.matches){

    /* mobile nav */

    new navigation_mobile()

  }else{

    /* reg nav */

    let dropdowns = document.querySelectorAll("header nav li.has-dropdown")
    dropdowns.forEach(function (dropdown) {
  
      let timer = 0
  
      dropdown.addEventListener("mouseenter", (event) => {
        clearTimeout( timer )
        dropdown.querySelector("ul.lvl-2").classList.add("active")
      })
  
      dropdown.addEventListener("mouseleave", (event) => {
        timer = setTimeout(function(){
          dropdown.querySelector("ul.lvl-2").classList.remove("active")
        },350)
      })
  
    })

  }

  new scrolllinks()
  new slideshows()

})

window.addEventListener('load', (event) => { 

  /* mailto Spam-Schutz */
  var encEmail = "aW5mb0BhcmJvdHJhZGUuZGU=";
  const forms = document.querySelectorAll('a[title="enc_mail"]');

  forms.forEach(function (form) {
    form.setAttribute("title", "Email senden")
    form.setAttribute("href", "mailto:".concat(atob(encEmail)));
  })

  /* forms */
  new form_handling()

  /* accordions */
  new accordions( gsap, ScrollTrigger )

})
