class slideshows{
    
    constructor(){

        const slideshows = document.querySelectorAll('.block.slideshow')
        if(slideshows){
            for(var i = 0; i < slideshows.length; i++){

                const slideshow = slideshows[i]

                let slideshowItems = slideshow.querySelector('ul.slider'),
                    prev = slideshow.querySelector('.prev_slide'),
                    next = slideshow.querySelector('.next_slide')

                const slideshowW = slideshow.offsetWidth
                const slide_count = slideshow.querySelectorAll('ul.slider > li').length
                
                let wrapperW = 0
                for(var s = 0; s < slide_count; s++){
                    wrapperW = wrapperW + slideshow.querySelectorAll('ul.slider > li')[s].offsetWidth
                }

                if(slide_count > 1 && wrapperW > slideshowW){ 
                    slide(slideshow, slideshowItems, prev, next) 
                }

                // pagination

                const pagination_buttons = slideshow.querySelectorAll('.block.slideshow .pagination > ul > li')
                for(var p = 0; p < pagination_buttons.length; p++){

                    const pagination_index = p
                    const pagination_button = pagination_buttons[p]
                    pagination_button.querySelector('a').addEventListener('click',(event) =>{
                        event.preventDefault()

                        slideshow.querySelector('.pagination ul > li.current').classList.remove("current")

                        slideshow.querySelector('ul.slider').classList.add('shifting')
                        
                        let spacing = slideshow.querySelector('ul.slider > li:nth-child(2)')
                        let style = spacing.currentStyle || window.getComputedStyle(spacing)

                        let pxVal = parseInt(style.marginLeft)

                        let left_style = (slideshow.querySelectorAll('ul.slider > li')[0].offsetWidth + pxVal) * pagination_index

                        slideshow.querySelector('ul.slider').style.left = ((-1) * (left_style)) + "px"

                        slideshow.querySelectorAll('.pagination ul > li')[pagination_index].classList.add("current")

                    })

                }

            }
        }

        function slide(slideshow, slides, prev, next){

            let index = 0,
                posInitial,
                slideshowItems = slideshow.querySelectorAll('ul.slider > li'),
                slidesLength = slideshowItems.length,
                allowShift = true

            let posX1 = 0,
                posX2 = 0,
                posFinal,
                threshold = 50,
                device = undefined

            slideshow.classList.add('loaded')
            slideshow.querySelectorAll('.pagination ul > li')[0].classList.add("current")
            if(prev){
                prev.classList.add('inactive')
                prev.style.display = 'block'
            }
            if(next){
                next.style.display = 'block'
            }

            // buttons
            if(prev){
                prev.addEventListener("mouseenter", (event) => {
                    if(!prev.classList.contains('inactive')){
                        slideshow.querySelector('ul.slider').classList.add('hover_prev')
                    }
                })
                prev.addEventListener("mouseleave", (event) => {
                    slideshow.querySelector('ul.slider').classList.remove('hover_prev')
                })
                prev.addEventListener('click', function (e) { 
                    e.preventDefault()
                    if(index > 0){ shift(-1) }
                    slideshow.querySelector('ul.slider').classList.remove('hover_prev')
                })
            }
            if(next){
                next.addEventListener("mouseenter", (event) => {
                    if(!next.classList.contains('inactive')){
                        slideshow.querySelector('ul.slider').classList.add('hover_next')
                    }
                })
                next.addEventListener("mouseleave", (event) => {
                    slideshow.querySelector('ul.slider').classList.remove('hover_next')
                })
                next.addEventListener('click', function (e) { 
                    e.preventDefault()
                    if(index < slidesLength - 1){ shift(1) }
                    slideshow.querySelector('ul.slider').classList.remove('hover_next')
                })
            }

            slides.addEventListener('transitionend', checkIndex)
            function checkIndex (){
                slides.classList.remove('shifting')
                allowShift = true
            }

            // move
            function shift(dir, action) {
                allowShift = false
                slides.classList.add('shifting')

                var spacing = slideshow.querySelector('ul.slider > li:nth-child(2)')
                var style = spacing.currentStyle || window.getComputedStyle(spacing)

                var pxVal = parseInt(style.marginLeft)
                if (!action) { posInitial = slides.offsetLeft }
                if (dir == 1) {
                    if(index == slidesLength - 2 && next){ next.classList.add('inactive') }
                    if(prev){ prev.classList.remove('inactive') }
                    index++
                    let left_style = 0
                    for(var l = 0; l < index; l++){
                        left_style = left_style + slideshow.querySelectorAll('ul.slider > li')[l].offsetWidth + pxVal
                    }
                    slides.style.left = ((-1) * (left_style)) + "px"
                } else if (dir == -1) {
                    if(index == 1 && prev){ prev.classList.add('inactive') }
                    if(next){ next.classList.remove('inactive') }
                    index--   
                    let left_style = 0
                    for(var l = 0; l < index; l++){
                        left_style = left_style + slideshow.querySelectorAll('ul.slider > li')[l].offsetWidth + pxVal
                    }
                    slides.style.left = ((-1) * (left_style)) + "px"
                } else {
                    slides.style.left = (posInitial) + "px"
                }

                if(slideshow.querySelector('.slider-status')){
                    slideshow.querySelector('.slider-status').innerHTML = index + 1
                }

                // pagination
                slideshow.querySelector('.pagination ul > li.current').classList.remove("current")
                slideshow.querySelectorAll('.pagination ul > li')[index].classList.add("current")

            }

        }

    }

}

export default slideshows
