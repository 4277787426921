class scrolling{

    constructor( gsap, ScrollTrigger ){

        const scrollLast = 0
        this.scrollLast = scrollLast

        // FadeOuts
        let sections = gsap.utils.toArray("section .block:not(.heading)")
        sections.forEach(function (section) {

            let element = section

            if(section.classList.contains("linklist")){

                /* Links (Startseite) */
                gsap.from(element, {
                    maskPosition: "0",
                    ease: "none",
                    scrollTrigger: {
                        trigger: element,
                        start: "top 38px",
                        end: "top top",
                        scrub: true
                    }
                })

            }else if(section.classList.contains("infobox")){

                /* infobox */
                if(section.offsetHeight > window.innerHeight){
                    gsap.from(element, {
                        maskPosition: "0",
                        ease: "none",
                        scrollTrigger: {
                            trigger: element,
                            start: "center top",
                            end: "bottom top",
                            scrub: true
                        }
                    })
                }else{
                    gsap.from(element, {
                        maskPosition: "0",
                        ease: "none",
                        scrollTrigger: {
                            trigger: element,
                            start: "top top",
                            end: "bottom top",
                            scrub: true
                        }
                    })
                }

            }else if(section.classList.contains("slideshow")){

                /* Slideshow */
                let slides = section.querySelectorAll(".slider > li")
                slides.forEach(function (slide) {
                    let slide_element = slide
                    gsap.from(slide_element, {
                        maskPosition: "0",
                        ease: "none",
                        scrollTrigger: {
                            trigger: section,
                            start: "center top",
                            end: "bottom top",
                            scrub: true
                        }
                    })
                })

            }else if(section.classList.contains("contactform")){

                /* Contactforms */
                gsap.from(element, {
                    maskPosition: "0",
                    ease: "none",
                    scrollTrigger: {
                        trigger: element,
                        start: "center top",
                        end: "bottom top",
                        scrub: true
                    }
                })

            }else if(section.classList.contains("newslist")){

                /* Newslist (News) */
                let news = section.querySelectorAll("li.news")
                news.forEach(function (element) {
                    let news_element = element
                    if(element.offsetHeight > window.innerHeight){
                        gsap.from(news_element, {
                            maskPosition: "0",
                            ease: "none",
                            scrollTrigger: {
                                trigger: news_element,
                                start: "center top",
                                end: "bottom top",
                                scrub: true
                            }
                        })
                    }else{
                        gsap.from(news_element, {
                            maskPosition: "0",
                            ease: "none",
                            scrollTrigger: {
                                trigger: news_element,
                                start: "top top",
                                end: "bottom top",
                                scrub: true
                            }
                        })
                    }
                })

            }else if(section.classList.contains("textblock")){

                /* Textelements */
                let paras = section.querySelectorAll("p")
                paras.forEach(function (para) {
                    let para_element = para
                    gsap.from(para_element, {
                        maskPosition: "0",
                        ease: "none",
                        scrollTrigger: {
                            trigger: para_element,
                            start: "top top",
                            end: "center top",
                            scrub: true
                        }
                    })
                })

            }else{

                /* else */
                gsap.from(element, {
                    maskPosition: "0",
                    ease: "none",
                    scrollTrigger: {
                        trigger: element,
                        start: "top top",
                        end: "bottom top",
                        scrub: true
                    }
                })

            }

        }) 
        if(document.querySelector(".cover .info")){

            /* Cover-Info-Element (Startseite) */
            let element = document.querySelector(".cover .info")
            gsap.from(element, {
                maskPosition: "0",
                ease: "none",
                scrollTrigger: {
                    trigger: element,
                    start: "top top",
                    end: "bottom top",
                    scrub: true
                }
            })

        }

        // Parallax
        if(document.querySelector(".cover .wrapper")){
            gsap.to(".cover .wrapper", {
                yPercent: 62,
                ease: "none",
                scrollTrigger: {
                    trigger: ".cover",
                    start: "top top",
                    end: "bottom top",
                    scrub: true
                }, 
            })
        }
        if(document.querySelector(".cover .info")){
            gsap.to(".cover .info", {
                yPercent: 62,
                ease: "none",
                scrollTrigger: {
                    trigger: ".cover",
                    start: "top top",
                    end: "bottom top",
                    scrub: true
                }, 
            })
        }

        this.updateSizes()
        this.initialCheck()
        document.addEventListener('scroll',() => this.draw(),{passive: true}) 
        window.addEventListener('load',() =>{
            this.updateSizes()
            this.setScrollTriggers()
        })
        window.addEventListener('resize',() => this.updateSizes(),{passive: true})  

    }

    scrolling(){}
    setScrollTriggers(){}
    updateSizes(){}
    initialCheck(){}
    draw(){}

}

export default scrolling
