class navigation_mobile {
    constructor(){
        
        const navigation_mobile_dropdown = this

        document.querySelector('.bttn-hamburger').addEventListener('click',(event) =>{
            event.preventDefault()

            const parent_li = document.querySelector('.bttn-hamburger')
            const target_dropdown = document.querySelector('body > header nav > div')

            if(!parent_li.classList.contains('is-active')){ 
                navigation_mobile_dropdown.open_lvl1( parent_li, target_dropdown )
            }else{ 
                navigation_mobile_dropdown.close_lvl1( parent_li, target_dropdown )
            }

        })

        const ddBttn_1 = document.querySelectorAll('ul.lvl-1 > li.has-dropdown')

        for(var i = 0; i < ddBttn_1.length; i++){

            const curr_btn_1 = ddBttn_1[i]
            ddBttn_1[i].querySelector("a > span + span").addEventListener('click',(event) =>{

                event.preventDefault()
                const parent_li = curr_btn_1
                const target_dropdown = curr_btn_1.querySelector("ul.lvl-2")

                if(!parent_li.classList.contains('is-active')){ 
                    navigation_mobile_dropdown.open_lvl2( parent_li, target_dropdown )
                }else{ 
                    navigation_mobile_dropdown.close_lvl2( parent_li, target_dropdown )
                }

            })

        }

    }

    open_lvl1(parent_li, dropdown) {

        if(!dropdown.classList.contains('visible')){

            document.querySelector('body').classList.add('prevent-scrolling')
            document.querySelector('body > header').classList.add('visible-nav')
            parent_li.classList.add('is-active')
            dropdown.style.position = 'absolute'
            dropdown.classList.add('visible')
            dropdown.style.opacity = '0'
            const height = window.innerHeight - 60

            requestAnimationFrame(() => {
                dropdown.style.position = null
                dropdown.style.height = '0'
                dropdown.style.opacity = null
                requestAnimationFrame(() => {
                    dropdown.style.height = `${height}px`
                    dropdown.classList.add('is-open')
                })
            })

        }

    }

    close_lvl1(parent_li, dropdown) {

        if(dropdown.classList.contains('is-open')){

            document.querySelector('body').classList.remove('prevent-scrolling')
            document.querySelector('body > header').classList.remove('visible-nav')
            parent_li.classList.remove('is-active')
            dropdown.classList.remove('is-open')
            dropdown.style.height = '0'
            dropdown.classList.remove('has-scrollbar')

            setTimeout(() => {
                dropdown.classList.remove('visible')
                dropdown.style.height = null
            }, 300)

        }

    }

    open_lvl2(parent_li, dropdown) {

        if(!dropdown.classList.contains('visible')){

            parent_li.classList.add('is-active')
            dropdown.style.position = 'absolute'
            dropdown.classList.add('visible')
            dropdown.style.opacity = '0'
            let height = dropdown.offsetHeight

            requestAnimationFrame(() => {
                dropdown.style.position = null
                dropdown.style.height = '0'
                dropdown.style.opacity = null
                requestAnimationFrame(() => {
                    dropdown.style.height = `${height}px`
                    dropdown.classList.add('is-open')
                })
            })

        }

    }

    close_lvl2(parent_li, dropdown) {

        if(dropdown.classList.contains('is-open')){

            parent_li.classList.remove('is-active')
            dropdown.classList.remove('is-open')
            dropdown.style.height = '0'

            setTimeout(() => {
                if( dropdown.querySelector("div.level_3.is-open") ){ 
                    dropdown.querySelector("li.is-active").classList.remove("is-active")
                    dropdown.querySelector("div.level_3.is-open").classList.remove("is-open")
                    dropdown.querySelector("div.level_3.visible").classList.remove("visible")
                }
                dropdown.classList.remove('visible')
                dropdown.style.height = null
            }, 300)

        }

    }

}

export default navigation_mobile
