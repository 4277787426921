class form_handling {
    constructor(){

        let current_value = 0
        let summed_up_value = 0

        const fields = document.querySelectorAll('.block.form_wuchshuelle .fieldgroup .column:first-of-type ul li')

        for(var i = 0; i < fields.length; i++){

            const field = fields[i]
            let input = field.querySelector("input")

            input.addEventListener("input",(event) => {

                summed_up_value = 0

                for(var v = 0; v < fields.length; v++){

                    current_value = 0
                    let current_field = fields[v]
                    let current_input = current_field.querySelector("input")
                    let fields_size = current_input.getAttribute('id')

                    if(fields_size == "l180_menge" || fields_size == "l150_menge"){
                        current_value = current_field.querySelector("input").value * 3
                    }else{
                        current_value = current_field.querySelector("input").value * 2
                    }

                    summed_up_value = parseInt(summed_up_value) + current_value

                }

                let additional_value = document.getElementById("sonderlaenge_menge").value
                document.getElementById("kabelbinder_menge").value = summed_up_value + (parseInt(additional_value) * 2)

            })

        }

        if(document.getElementById("sonderlaenge_menge")){

            document.getElementById("sonderlaenge_menge").addEventListener("input",(event) => {

                summed_up_value = 0

                for(var v = 0; v < fields.length; v++){

                    current_value = 0
                    let current_field = fields[v]
                    let current_input = current_field.querySelector("input")
                    let fields_size = current_input.getAttribute('id')

                    if(fields_size == "l180_menge" || fields_size == "l150_menge"){
                        current_value = current_field.querySelector("input").value * 3
                    }else{
                        current_value = current_field.querySelector("input").value * 2
                    }

                    summed_up_value = parseInt(summed_up_value) + current_value

                }

                let additional_value = document.getElementById("sonderlaenge_menge").value
                document.getElementById("kabelbinder_menge").value = summed_up_value + (parseInt(additional_value) * 2)

            })

        }

    }
}

export default form_handling
