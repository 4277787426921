class accordions {
    constructor( gsap, ScrollTrigger ){

        const accordions = document.querySelectorAll('.block.accordion')

        for(var i = 0; i < accordions.length; i++){
        
            let accordion = accordions[i]
            accordion.querySelector('h4').addEventListener('click',(event) =>{
                event.preventDefault()
                this.toggle( gsap, ScrollTrigger, accordion )
            })

        }

    }

    toggle(gsap, ScrollTrigger, accordion){

        ScrollTrigger.refresh()

        if (accordion.querySelector('.content').classList.contains("is-collapsed")) {

            accordion.querySelector('.headline').classList.add("active")
            accordion.querySelector('.content').classList.remove("is-collapsed")
            gsap.to(accordion.querySelector('.content'), {
                duration: 0.6,
                height: "auto",
                ease: "power4.out",
                onComplete: function(){
                    ScrollTrigger.refresh()
                }
            })
            gsap.to(accordion.querySelector('.content > div'), {
                duration: 0.4,
                opacity: 1,
                ease: "power4.out"
            })

        } else { 

            accordion.querySelector('.headline').classList.remove("active")
            accordion.querySelector('.content').classList.add("is-collapsed")
            gsap.to(accordion.querySelector('.content'), {
                duration: 0.4, 
                height: 0,
                ease: "power4.out",
                onComplete: function(){
                    ScrollTrigger.refresh()
                }
            })
            gsap.to(accordion.querySelector('.content > div'), {
                duration: 0.2,
                opacity: 0,
                ease: "power4.out"
            })

        }

    }
}

export default accordions
