class scrolllinks{
    constructor(){

        function scrollto(distance){
            document.scrollingElement.scroll({
                top: distance,
                behavior: 'smooth'
            })
        }
        
        let mq900_max = window.matchMedia('screen and (max-width: 900px)')

        const scroll_links = document.querySelectorAll('a[href^="#"]')
        scroll_links.forEach((link) => {

            let href = link.getAttribute("href")
            let id = href.slice(1)
            link.addEventListener('click',(event) =>{
                event.preventDefault()

                let distance
                if (mq900_max.matches) {
                    //
                    distance = document.getElementById(id).getBoundingClientRect().top + window.scrollY - document.querySelector("body > header").offsetHeight + (((window.innerHeight / 100 * 1.5625) + 8) * 2)
                }else{
                    // 
                    distance = document.getElementById(id).getBoundingClientRect().top + window.scrollY - document.querySelector("body > header").offsetHeight + (((window.innerHeight / 100 * 1.5625) + 8) * 2)
                }

                scrollto(distance)
            })

        })

    }
}

export default scrolllinks
